.video-section-container {
    background-color: #f9f9f9; /* Light background color */
    border-radius: 15px;       /* Circular borders */
    padding: 20px;             /* Padding around the title and video */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
 
}

.video-section-title {
    font-size: 1.8rem; /* Adjust title size */
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;       /* Darker text color */
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* Maintain 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    border-radius: 12px;  /* Circular borders for the video container */
    background-color: #fff; /* Light background for video */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px; /* Circular borders for iframe */
}

.youtube-box{
    padding-top: 1rem;
}