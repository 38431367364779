/* Consent Banner Styles */
.consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Dark background for visibility */
    color: white;
    padding: 20px;
    text-align: center;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .consent-content {
    max-width: 960px; /* Center content and restrict width */
    margin: 0 auto;
  }
  
  .consent-content h5 {
    margin: 0;
    font-weight: bold;
  }
  
  .consent-content p {
    margin: 10px 0;
  }
  
  .consent-actions {
    margin-top: 10px;
  }
  
  .consent-actions button {
    margin: 0 10px;
    padding: 8px 16px;
    border-radius: 5px;
  }
  
  /* Button Styles */
  .consent-actions button.btn-primary {
    background-color: #007bff;
    border: none;
    cursor: pointer;
  }
  
  .consent-actions button.btn-link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .consent-banner {
      padding: 15px;
    }
  
    .consent-content p {
      font-size: 14px;
    }
  
    .consent-actions button {
      margin: 5px;
      padding: 8px 12px;
    }
  }
  