.footer-container {
    background-color: #2c2f33;
    color: #fff;
    padding: 2rem 0;
    font-size: 0.9rem;
}

.footer-container .footer-logo img {
    margin-bottom: 0rem;
}

.footer-container h5 {
    color: #7289da;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.footer-container ul {
    list-style: none;
    padding: 0;
}

.footer-container ul li {
    margin-bottom: 0.5rem;
}

.footer-container ul li a {
    color: #99aab5;
    text-decoration: none;
}

.footer-container ul li a:hover {
    color: #fff;
}

.footer-container .social-icons {
    margin: 1rem 0;
}

.footer-container .social-icons .fa-icon {
    font-size: 1.5rem;
    color: #fff;
    transition: color 0.3s;
}

.footer-container .social-icons .fa-icon:hover {
    color: #7289da;
    cursor: pointer;
}

.footer-container p {
    margin-top: 1rem;
    color: #99aab5;
}

.footer-bottom {
    border-top: 1px solid #7289da;
    padding-top: 1rem;
    margin-top: 2rem;
}

.footer-button {
    margin: 0.5rem 0;
    width: 100px;
}

.svg-icon {
    fill: white;
    width: 24px; /* You can adjust the size here */
    height: 24px;
    margin: 3px;
    transition: fill 0.3s ease; /* Smooth transition for the color change */
}


/* Change the fill color on hover */
.svg-icon:hover {
    fill: #00acee; /* Change to any color you like, for example, Twitter blue */
}