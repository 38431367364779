/* CinematographyFeatures.css */

.cinematography-section {
    padding: 2rem 0; /* Adjust padding as needed */
}

.cinematography-title {
    font-size: 1rem !important;
}


.cinematography-card {
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1); /* Add subtle shadow */
    overflow: hidden; /* Prevents content overflow */
    display: flex; /* Use flexbox for centering */
    flex-direction: column; /* Arrange items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
}


.cinematography-icon {
    font-size: 1.7rem; /* Adjust icon size as needed */
    margin-bottom: 1rem; /* Add margin below the icon */
}

.cinematography-card .card-title {
    text-align: center; /* Center title */
    margin-bottom: 0.5rem; /* Adjust margin below the title */
}

.cinematography-card .card-text {
    text-align: center; /* Center description */
}

/* Responsive styles (Adjust breakpoints if needed) */
@media (max-width: 768px) {
    .cinematography-card {
        width: 100%; /* Full width on smaller screens */
    }
}
