.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 0.5rem;
    margin-top: 4rem;
}

.faq-item {
    border: none;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
}

.faq-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.faq-header {
    background: #f8f9fa;
    border: none;
    
    cursor: pointer;
}

.faq-button {
    text-align: left;
    padding: 0;
    color: #000;
    width: 100%;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-button:hover {
    text-decoration: none;
    background: none;
}

.faq-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.faq-question {
    margin: 0;
    font-size: 1.1rem;
    text-align: left;
}

.faq-icon {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

.faq-answer {
    padding: 1rem 1.5rem;
    background: #fff;
    border-top: 1px solid #e9ecef;
    text-align: justify;
    font-size: 1rem !important;
}
