/* InsightAISection.css */

.insight-ai-section {
    background: #fff;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow - adjust as needed */
    overflow: hidden; /* Prevents content overflow */
    padding-left: 0.25rem;    
    padding-right: 0.25rem;    
}


.insight-ai-section p {
    text-align: center;
}

.insight-ai-section .feature-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.insight-ai-section .insightai-feature-item {
    border-radius: 10px;
    flex: 1 0 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;    
}

.insight-ai-section .feature-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.insight-ai-section .insightai-feature-icon {
    color: #365dc1;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 1.5rem; /* Adjust icon size - match DroneAISection */
    margin-bottom: 0.5rem; /* Adjust margin - match DroneAISection */
    
}

.insight-ai-section .feature-image {
    display: block;
    max-width: 100%; /* Ensure it doesn't overflow container on smaller screens */
    height: auto;
    object-fit: cover; /* Cover the entire container, potentially cropping image */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .insight-ai-section .insightai-feature-item {
        width: 100%;
        margin-bottom: 1rem;
    }

     .insight-ai-section .feature-image {
        width: 100%; /* Full width on smaller screens */
        height: auto;
    }
}


.insightai-feature-title {
    font-size: 1.1rem;
}