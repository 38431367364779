.control-options-section {
    background: linear-gradient(135deg, #e0f7fa, #80deea);
    padding: 2rem;
    border-radius: 15px;
}


.feature-card {
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 15px;
    padding: 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.feature-icon {
    color: #007bff; 
    cursor: pointer;
    transition: color 0.3s;
}


.info-icon {
    color: #777;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: color 0.3s;
}

.info-icon:hover {
    color: #333;
}

.custom-modal .modal-content {
    background-color: #343a40; /* Dark background */
    color: #ffffff; /* Light text */
    border-radius: 10px;
}

.custom-modal .modal-header {
    border-bottom: none;
}

.custom-modal .modal-title {
    color: #ffffff;
}

.custom-modal .modal-footer {
    border-top: none;
}

.custom-modal .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.custom-modal .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

.drone-control-title {
    font-size: 1.1rem !important;
}
