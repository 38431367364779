.list-text {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}


.title-icons{
 font-size: 2rem;
}

@media (max-width: 576px) {
    
    .align-items-center.my-2 {
        margin: 0.5rem 0; /* Reduce vertical spacing between list items */
    }

    .list-unstyled li {
        margin-left: 0; /* Remove left margin for better alignment */
    }

    /* Adjust the spacing between the icon and the text */
    .list-text {
        margin-left: 0.5rem;
        font-size: 1rem;
    }

    .title-icons{
        font-size: 1.5rem;
    }
}


/* Keyframes for fade-in effect */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
  

.fade-in {
    animation: fadeIn 0.5s ease-in-out forwards;
}
