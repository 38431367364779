.home-container {
  margin-top: 4vh;
  margin-bottom: 10vh;
}

.welcome-title {
  font-size: 7rem; /* or any size you need */
  font-family: 'Roboto', sans-serif; /* Specify your desired font here */
}

/* Sparkle Icon Animation */
.home-sparkle-icon {
  font-size: 1.5rem; /* Adjust size as needed */
  color: gold;
  animation: sparkleAnimation 1s infinite linear; 
}

@keyframes sparkleAnimation {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(10deg); opacity: 0.8; }
  100% { transform: rotate(0deg); }
}
.download-buttons-container {
  display: flex;
  justify-content: center; /* Adjust as needed: flex-start, flex-end, space-between, space-around, etc. */
  align-items: center;
}

.image-overlay {
    position: relative;
    display: inline-block;
}

.image-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0e0c1e;
    opacity: 0; /* Adjust the opacity as needed */
}

.content-container {
    max-width: 70%;
    margin: 0 auto; /* Center the container */
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: red;
}

.custom-navbar-bg {
    background-color: #0e0c1e;
}

.icon-colors{
    color: white;
}

.icon-heading-container {
    display: flex;
    align-items: center; /* Vertically align the icon and text */
}

.heading-text {
    margin-left: 10px; /* Add spacing between icon and text */
}

.gradient-text {
    background: linear-gradient(135deg, #1948f0, #06fa99); /* Adjust these colors */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  /* Give the button a unique, eye-catching gradient with smooth transitions */
.home-container .custom-download-button {
  background: linear-gradient(to right, #c33764, #1d2671) !important; /* Purple/Pink Gradient */
  border: none !important;
  border-radius: 30px !important;       /* Make the button more pill-shaped */
  color: #ffffff !important;           /* White text */
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem 2rem;
  box-shadow: 0 5px 15px rgba(29, 38, 113, 0.4); /* Subtle purple shadow */
  transition: background 0.3s ease, 
              transform 0.3s ease,
              box-shadow 0.3s ease,
              opacity 0.3s ease;
  opacity: 0;                 /* Start invisible if animating in */
  transform: scale(0.8);      /* Slightly smaller initially */
}

/* When you add "fade-in" dynamically from React, it transitions to visible */
.home-container .custom-download-button.fade-in {
  opacity: 1;
  transform: scale(1);
}

/* Add a dramatic hover effect */
.home-container .custom-download-button:hover {
  background: linear-gradient(to right, rgba(225, 24, 108, 0.8), rgba(212, 24, 225, 0.8)) !important; /* Deeper purple gradient */
  transform: scale(2);  
  box-shadow: 0 8px 20px rgba(138, 43, 226, 0.6); /* Larger glow */
  color: #fff !important; 
}


#logging {
  padding-top: 0.06rem;
}

.icon-heading-container {
  border-radius: 32px;
  box-shadow:
    1px 1px 0 1px #1717c5,
    -1px 0 28px 0 rgb(11, 8, 188),
    28px 28px 28px 0 rgba(19, 17, 184,0);
  transition:
    .4s ease-in-out transform,
    .4s ease-in-out box-shadow;
  width: 350px;
  height: 350px;
  align-items: center;
  justify-content: center;
}
  
.icon-heading-container:hover {
  transform:
    translate3d(0px, -16px, 0px)
    rotateX(3deg);
  box-shadow:
    -1px 0 28px 0 rgb(21, 18, 155),
    54px 54px 28px -10px rgb(24, 21, 182);
}

.home-subheader {
  max-width:100%;
  align-items: center;
  justify-content: center;
}

.iframe-storylane-box {
  margin-top: 1rem;
  position:relative;
  padding-bottom:calc(56.25% + 25px);
  width:100%;
  height:0;
  transform:scale(1)
}

.iframe-storylane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(63, 95, 172, 0.35);
  box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
}


.welcome-icons {
  color:#9a32f0e6;
  font-size: 2rem;
}


/* Keyframes for fade-in effect */
@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Initially hide all items */
.hidden {
  visibility: hidden; /* Keep the space occupied, but hide the content */
  opacity: 0;
}



/* Adjust font size and spacing for mobile view */
@media (max-width: 576px) {
  .bigger-text {
      font-size: 0.9rem; /* Make the text smaller for mobile view */
  }

  .home-subheader {
      max-width: 100%; /* Increase the width slightly to make more space */
  }

  .welcome-icons {
    color:#9a32f0e6;
    font-size: 1.6rem;
  }
  
  .welcome-title {
    font-size: 4rem; 
  }
  
  .custom-buttons-container {
      flex-direction: column; /* Stack buttons vertically on mobile */
      align-items: center;
  }


  .full-width {
    width: 90%; /* Make buttons full-width on mobile for better tap targets */
  }

  .hide-on-mobile {
    display: none !important;
  }
  
  .iframe-storylane-box {
    /* margin-top: 0.5rem; */
    position:relative;
    padding-bottom:calc(56.25% + 25px);
    width:100%;
    height:0;
    transform:scale(1)
  }

  .home-container .custom-download-button {
    font-size: 0.90rem;
    width: 60%;
  }
}

.scroll-link {
  color: #007bff; /* Blue color for links */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold for emphasis */
  margin-left: 0.2rem; /* Add spacing between link and preceding text */
}

.scroll-link:hover {
  text-decoration: underline; /* Underline on hover */
  cursor: pointer;
}

.call-to-action-section {
 width: 100%; 
}