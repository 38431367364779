/* src/styles/NavbarMain.css */
  
.nav-link {
    color: #3760f5 !important; /* Purple text color */
    margin-right: 1.5rem;
    font-size: 1rem; /* Adjust font size */
    cursor: pointer  !important;
}

.nav-link:hover {
    color: #b19cd9 !important; /* Lighter purple on hover */
    cursor: pointer  !important;
}


.user-icon {
    font-size: 1.2rem; /* Adjust icon size */
    margin-right: 0.5rem;
}

/* Assuming you have or will create a CSS file where these can be placed */
.navbar-brand {
    display: flex;
    align-items: center; /* This vertically centers the items in the flex container */
    justify-content: center; /* This horizontally centers the items if needed */
    gap: 0.5rem; /* Optional: Adds space between the logo and the text */
}
  

.navbar-brand-text {
    display: inline-block; 
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 20px; 
    color: transparent;
    font-weight: bold;
    background: linear-gradient(90deg, rgb(212, 0, 255) 0%, rgb(0, 136, 255) 100%);
    -webkit-background-clip: text; 
    background-clip: text; 
    padding-left: 5px;
}

.navbar-logo {
    width: auto;
    height: 50px;
}

/* Center the nav links when the navbar is collapsed (mobile view) */
@media (max-width: 991px) { /* Bootstrap uses 991px as the breakpoint for the navbar collapse */
    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .navbar-nav .nav-link {
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }
  }

.navDropdown-item {
    cursor: pointer;
}