.cta-box {
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.call-action-title {
    font-size:1.5rem;
}

.cta-box p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.cta-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button:hover {
    background-color: #007bff;
    color: #fff;
}

.cta-section{
    margin-top: 1rem;
}

