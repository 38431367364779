/* Existing CSS */

.feature-container {
    background: #fff;
    border-radius: 15px;
    padding: 1rem;
    
    position: relative; /* Ensure proper positioning */
    overflow: hidden; /* To handle the glassmorphic background */
}

.ai-feature {
    margin-top: 10rem;
}

.ai-feature-description {
    padding-top: 2rem;
}

.rounded-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.image-container {
    position: relative;
    overflow: hidden;
}

.image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
    border-radius: 15px;
}

.ai-feature {
    overflow-x: hidden;
}

.feature-list {
    list-style-type:"";
    padding-left: 20px;
    text-align: left;
}

.feature-list li {
    margin-bottom: 8px;
    text-align: center;
}


.ai-title {
    color: grey;
}

.custom-flow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center all flow items */
    gap: 2rem; /* Add space between items */
    padding: 2rem; /* Padding around the entire container */
}

.flow-item {
    display: flex;
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center-align content */
    text-align: center; /* Center text alignment */
    width: 45%; /* Adjust width to fit 2 items per row on desktop */
    max-width: 300px; /* Restrict the maximum width */
    padding: 1.5rem;
    border: 1px solid #ddd; /* Subtle border for separation */
    border-radius: 10px; /* Rounded corners for modern look */
    background: #f9f9f9; /* Light background for better contrast */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.flow-item:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.flow-icon {
    font-size: 2rem; /* Icon size */
    color: #007bff; /* Blue color for icons */
    margin-bottom: 1rem; /* Space between icon and text */
}

.drone-ai-icons {
    font-size: 2rem; /* Icon size */
    color: #007bff; /* Blue color for icons */
}

.flow-content {
    font-size: 1rem; /* Standard text size */
    line-height: 1.5; /* Proper line spacing */
    color: #6c757d; /* Muted text color */
}

.drone-ai-feature-title {
    font-size: 1.1rem;
}

.flow-link {
    color: #007bff; /* Blue color for links */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Bold for emphasis */
    margin-left: 0.2rem; /* Add spacing between link and preceding text */
}

.flow-link:hover {
    text-decoration: underline; /* Underline on hover */
}

.section-subheading {
    font-size: 1.6rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .custom-flow {
        justify-content: center; /* Center-align items on mobile */
    }

    .flow-item {
        width: 90%; /* Full width on mobile */
    }

    .flow-content {
        font-size: 0.9rem; /* Slightly smaller text for mobile */
    }

    .drone-ai-feature-title {
        font-size: 1rem;
    }
    
    .drone-ai-icons{
        font-size: 1.5rem;
    }

    .section-subheading {
        font-size: 1.4rem;
    }

    .section-sub-heading {
        visibility: hidden;
        height: 0;
    }
}
