/* Overall container spacing */
.download-section {
    padding: 5px 10px;
    margin-top: 10px;
  }
  
  /* Gradient text styling */
  .gradient-text {
    background: linear-gradient(to right, #007bff, #6610f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }

  .download-title {
    font-size: 3rem;
  }
  
  /* Icons */
  .apple-icon {
    color: #c0c0c0; /* Apple grey color */
  }
  
  .windows-icon {
    color: #00A4EF; /* Windows blue color */
  }
  
  /* Custom Flow Container */
  .custom-flow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center all flow items */
    gap: 2rem; /* Add space between items */
    margin-bottom: 2rem; /* Spacing from the next section */
  }
  
  /* Each flow item */
  .flow-item {
    display: flex;
    flex-direction: column; /* Stack icon, title, button vertically */
    align-items: center;
    text-align: center;
    width: 250px; /* Optional: fixed width or 45% on large screens */
    padding: 1.5rem;
    border: 1px solid #ddd; /* Subtle border for separation */
    border-radius: 10px; /* Rounded corners for modern look */
    background: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .flow-item:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
  }
  
  /* Button inside each flow-item */
  .btn-download {
    width: 100%;
    margin-top: auto;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .btn-download:hover {
    background-color: #0056b3;
    border-color: #0056b3;
    color: #fff;
  }
  
  /* For the "trusted badges" container */
  .trusted-badges {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
  
  /* Link styling */
  .a-onclick {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .a-onclick:hover {
    color: #0056b3;
    text-decoration: none;
  }
  
  /* Responsive adjustments */
  @media (max-width: 767px) {
    
    .custom-flow {
      gap: 1rem;
    }
  
    .flow-item {
      width: 80%;
      margin: 0 auto;
    }


    .download-title {
        font-size: 2rem;
    }
  }
  

  .public-download-name {
   font-size: 1.25rem; 
  }