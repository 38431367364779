.user-types-section {
    background: #f8f9fa;
    padding: 3rem 0;
}

.user-type-card {
    border: none;
    border-radius: 20px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
}

.user-type-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.user-type-image {
    height: 200px;
    object-fit: cover;
}

.icon-container {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    top: -40px;
}

.user-type-card .card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: -20px;
}

.user-type-card .card-text {
    font-size: 1rem;
    color: #6c757d;
}

.learn-more-button {
    background-color: transparent;
    border-color: #007bff;
    color: #007bff;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    transition: background-color 0.3s, color 0.3s;
}

.learn-more-button:hover {
    background-color: #007bff;
    color: #fff;
}


.user-type-title {
    font-size: 1.2rem !important;
}